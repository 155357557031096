body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #fbfbfb !important;
  height: 100%;
}

.App-header {
  padding-top: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-modal-wrap {
  z-index: 9999 !important;
}

.split-card {
  background: #fbfbfb !important;
  min-width: 300px;
  padding-bottom: 24px;
  max-height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.split-card-ipad {
  background: #fbfbfb !important;
  min-width: 300px;
  padding-bottom: 24px;
  max-height: calc(100vh - 190px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.split-card-mobile {
  background: #fbfbfb !important;
  width: 100%;
}

.steps-content {
  border: 0px solid #e9e9e9;
  border-radius: 6px;
  /* background-color: #fafafa; */
  /* min-height: 200px; */
  text-align: center;
  padding: 0 18px;
  margin: 0 auto;
  /* height: 62vh; */
}

.budget-content {
  text-align: center;
  padding: 0 36px 0 36px;
  margin: 0 auto;
}

.mortgage-content {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.steps-content-mobile {
  border: 0px solid #e9e9e9;
  border-radius: 6px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.report {
  text-align: center;
  padding: 0 24px;
  margin: 0 auto;
  overflow: hidden;
}

.steps-action {
  margin-top: 24px;
}

.upload-list-inline .ant-upload-list-item {
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.checkbox-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.stepForm {
  display: inline-block;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.Pane {
  overflow-y: auto;
  max-height: 80%;
}

.SplitPane {
  height: auto !important;
}

.closing-cost-extra {
  margin-top: 4px;
  margin-bottom: 0;
  text-align: start;
}

.app-content {
  background: #fbfbfb !important;
  margin-top: 64px;
  padding: 0 50px;
}

.report-buttons {
  text-align: right;
  float: right;
  margin: 12px;
}

.report-button {
  margin-left: 12px;
}

.expense-donut-chart {
  width: 90%;
}

.expense-input-group-left {
  width: 70% !important;
  float: left !important;
  text-align: left !important;
}

.expense-input-group-right {
  width: 30% !important;
  float: left !important;
  text-align: left !important;
}

#homepage {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
}


#homepage-mobile {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 8px 16px 8px;
}

.form-card {
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  background: #fff;
}

@media all and (max-width: 1500px) {
  .report-button {
    margin-left: 8px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media all and (max-width: 767px) {
  .homepage-col {
    margin-top: 16px;
  }
  .expense-input-group-left {
    width: 60% !important;
    float: left !important;
    text-align: left !important;
  }
  .expense-input-group-right {
    width: 40% !important;
    float: left !important;
    text-align: left !important;
  }
}

@media all and (max-width: 480px) {
  .app-content {
    margin-top: 64px;
    padding: 0 10px;
  }
  .report {
    padding: 0 8px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .report-buttons {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: center;
    float: center;
  }
  .report-button {
    margin-top: 4px;
  }
  .expense-donut-chart {
    padding-bottom: 28px;
    width: 100%;
    overflow: auto;
  }
  .budget-content {
    text-align: center;
    padding: 0 12px 0 12px;
    margin: 0 auto;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  opacity: 0.1;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.previous-reports {
  background: #fbfbfb !important;
  width: 100%;
  max-width: 1000px;
  margin: 8px auto;
}

.rental-watchlist {
  background: #fbfbfb !important;
  margin: 8px 4px 0 4px;
}

.infowindow {
  text-align: start;
}

.infowindow p {
  margin-bottom: 0.5em;
  font-size: 14px;
}

.Pushpin{
  line-height:1;
  height:0;
  position: absolute;
  z-index: 100;
}
.Pushpin .PushpinContent{
  display:inline-block;
  top:-23px;
  left:-50%;
  position:relative;
  border:1px solid #fff;
  border-radius:4px;
  font-size:12px
}
.Pushpin .PushpinContent .inner-pushpin-content,.Pushpin .PushpinContent .pushpin-content{
  border-radius:3px;
  background:#2e7e36;
  border:1px solid #2e7e36;
  cursor:pointer
}
.Pushpin .PushpinContent .inner-pushpin-content .lightning-bolt,.Pushpin .PushpinContent .inner-pushpin-content .logo-R,.Pushpin .PushpinContent .pushpin-content .lightning-bolt,.Pushpin .PushpinContent .pushpin-content .logo-R{
  height:10px!important;
  width:10px!important;
  fill:#c82021!important;
  stroke:none;
  background-color:#fff;
  border-top-left-radius:2px;
  border-bottom-left-radius:2px;
  position:static;
  vertical-align:bottom;
  padding:3px 2px
}
.Pushpin .PushpinContent .inner-pushpin-content .price,.Pushpin .PushpinContent .pushpin-content .price{
  color:#fff;
  font-weight:700;
  padding:3px 3px 2px;
  display:inline-block;
  font-size:11px;
  line-height:11px
}
.Pushpin .PushpinContent .inner-pushpin-content.mobile .logo-R,.Pushpin .PushpinContent .pushpin-content.mobile .logo-R{
  padding:4px 2px 3px
}
.Pushpin .PushpinContent .inner-pushpin-content.mobile .price,.Pushpin .PushpinContent .pushpin-content.mobile .price{
  padding:3px
}
.Pushpin .PushpinContent .inner-pushpin-content.selected,.Pushpin .PushpinContent .pushpin-content.selected{
  background:#e62e2e;
  border:1px solid #e62e2e
}
.Pushpin .PushpinContent .inner-pushpin-content.selected:after,.Pushpin .PushpinContent .inner-pushpin-content.selected:before,.Pushpin .PushpinContent .pushpin-content.selected:after,.Pushpin .PushpinContent .pushpin-content.selected:before{
  border-top-color:#e62e2e
}
.Pushpin .PushpinContent .inner-pushpin-content.backup,.Pushpin .PushpinContent .pushpin-content.backup{
  background:#629e68;
  border:1px solid #629e68
}
.Pushpin .PushpinContent .inner-pushpin-content.backup:after,.Pushpin .PushpinContent .inner-pushpin-content.backup:before,.Pushpin .PushpinContent .pushpin-content.backup:after,.Pushpin .PushpinContent .pushpin-content.backup:before{
  border-top-color:#629e68
}
.Pushpin .PushpinContent .inner-pushpin-content.madeOffer,.Pushpin .PushpinContent .pushpin-content.madeOffer{
  background:#326ec2;
  border:1px solid #326ec2
}
.Pushpin .PushpinContent .inner-pushpin-content.madeOffer:after,.Pushpin .PushpinContent .inner-pushpin-content.madeOffer:before,.Pushpin .PushpinContent .pushpin-content.madeOffer:after,.Pushpin .PushpinContent .pushpin-content.madeOffer:before{
  border-top-color:#326ec2
}

.Pushpin .PushpinContent .inner-pushpin-content.madeOfferButFailed,.Pushpin .PushpinContent .pushpin-content.madeOfferButFailed{
  background:#d46b08;
  border:1px solid #d46b08
}
.Pushpin .PushpinContent .inner-pushpin-content.madeOfferButFailed:after,.Pushpin .PushpinContent .inner-pushpin-content.madeOfferButFailed:before,.Pushpin .PushpinContent .pushpin-content.madeOfferButFailed:after,.Pushpin .PushpinContent .pushpin-content.madeOfferButFailed:before{
  border-top-color:#d46b08
}

.Pushpin .PushpinContent .inner-pushpin-content.pending,.Pushpin .PushpinContent .pushpin-content.pending{
  background:#dd3882;
  border:1px solid #dd3882
}
.Pushpin .PushpinContent .inner-pushpin-content.pending:after,.Pushpin .PushpinContent .inner-pushpin-content.pending:before,.Pushpin .PushpinContent .pushpin-content.pending:after,.Pushpin .PushpinContent .pushpin-content.pending:before{
  border-top-color:#dd3882
}

.Pushpin .PushpinContent .inner-pushpin-content:after,.Pushpin .PushpinContent .inner-pushpin-content:before,.Pushpin .PushpinContent .white-border{
  top:calc(100% - 1px);
  left:50%;
  border:solid transparent;
  content:" ";
  height:0;
  width:0;
  position:absolute
}
.Pushpin .PushpinContent .inner-pushpin-content:before{
  border-top-color:#2e7e36;
  border-width:4px;
  margin-left:-4px
}
.Pushpin .PushpinContent .inner-pushpin-content:after{
  border-top-color:#2e7e36;
  border-width:3.5px;
  margin-left:-3px
}
.Pushpin .PushpinContent .white-border{
  border-top-color:#fff;
  border-width:4px;
  margin-left:-4px;
  top:100%
}
.Pushpin .upper-right-badge{
  position:absolute;
  left:calc(100% - 5px)
}
.Pushpin .stroked-icon{
  stroke:#fff;
  stroke-width:3px;
  height:10px;
  width:10px
}
.Pushpin .video-camera-icon{
  top:-8px;
  fill:#7556f2;
  height:12px;
  width:12px
}
.Pushpin .upper-center-badge{
  position:absolute;
  left:calc(100% - 18px)
}
.Pushpin .Hot svg,.Pushpin .Open svg{
  position:absolute;
  left:-5px;
  top:-50%;
  border:1px solid #fff;
  border-radius:2px;
  height:9px;
  width:18px
}
.Pushpin .Flyout{
  cursor:default
}
.Pushpin .Flyout.position-left{
  top:-13px;
  left:-30px
}
.Pushpin .Flyout.position-right{
  top:-13px;
  left:30px
}
.Pushpin .Flyout.position-above{
  top:-23px;
  left:0
}
.Pushpin .Flyout.position-below{
  top:0;
  left:0
}